import React from 'react';

const Logo = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      height="50px"
      viewBox="0 0 1289.04 437.01"
      xmlSpace="preserve"
    >
      <g id="Layer_1">
        <path
          d="m373.67,363.99c.02-4.73.13-9.77-.18-15.01-.22-8.94-.87-18.67-1.95-28.95l-3.19-.68-3.77-21.03,2.52-9.55c-3.92-21.96-9.8-45.61-18.07-69.87l-3.72.75-5.37-15.38.65-8.14c-.96-2.4-1.9-4.79-2.91-7.19-8.56-20.7-18.21-39.97-28.24-57.71l-9.68-5.94-14.96-25.65s-19.44-34.97-34.56-53.94C225.1,15.65,206.71,0,206.71,0c0,0,12.67,22.54,31.16,59.11,2.21,4.37,4.5,8.96,6.86,13.72,5.09,10.32,12.7,17.33,13.68,17.92.97.59,13.62,21.21,13.62,21.21l.58,8.71,12.82,18.35,11.82,24.64-4.9,11.59c3.9,9.03,7.82,18.24,11.7,27.64,1.39,3.31,2.75,6.59,4.1,9.87l10.21,8.47,7.84,25.43s2.78,14.15,5.83,28.63c5.95,16.88,11.14,32.88,15.55,47.69l6.44,10.17,11.94,42.4-1.26,15.93c.56,2.63,1.08,5.14,1.49,7.39,2.01,11.36,3.32,17.75,3.32,17.75,0,0,.97-6.35,2.37-17.99.69-5.82,1.01-12.95,1.55-21.3.25-4.17.18-8.61.26-13.35Z"
          fill="#192b51"
        />
        <path
          d="m304.84,374.85c.02-4.73.13-9.77-.18-15.01-.22-8.94-.87-18.66-1.94-28.96l-3.19-.68-3.77-21.03,2.52-9.55c-3.92-21.96-9.8-45.62-18.07-69.87l-3.71.75-5.36-15.38-6.56-16.49c-.96-2.39-4.85-9.45-5.86-11.85-8.56-20.7-8.05-26.95-18.09-44.69l-9.68-5.94-14.96-25.65s-19.44-34.97-34.56-53.94c-25.14-30.05-43.53-45.7-43.53-45.7,0,0,12.67,22.54,31.16,59.11,2.21,4.37,4.5,8.96,6.86,13.72,5.09,10.32,12.7,17.34,13.68,17.92.97.59,13.61,21.21,13.61,21.21l.59,8.71,12.82,18.35.56,14.4,6.35,21.83c3.9,9.03,7.81,18.24,11.7,27.64,1.39,3.3,2.75,6.59,4.09,9.87l10.21,8.47,7.84,25.43s2.78,14.15,5.83,28.63c5.95,16.88,11.14,32.87,15.55,47.68l6.44,10.17,11.94,42.41-1.26,15.92c.56,2.64,1.08,5.14,1.49,7.4,2.01,11.37,3.32,17.75,3.32,17.75,0,0,.97-6.35,2.37-17.99.68-5.82,1.01-12.95,1.55-21.3.25-4.17.18-8.61.25-13.35Z"
          fill="#192b51"
        />
        <path
          d="m236.11,384.37c.02-4.73.13-9.77-.18-15.01-.22-8.94-.87-18.67-1.94-28.96l-3.19-.68-3.78-21.03,2.52-9.55c-3.92-21.96-9.8-45.61-18.07-69.87l-3.72.75-5.36-15.38.64-8.14c-.96-2.39-1.9-4.79-2.91-7.19-8.56-20.7-18.21-39.97-28.24-57.71l-18.86-40.31-13.66-16.37s-11.57-9.88-26.69-28.84c-25.14-30.05-43.53-45.7-43.53-45.7,0,0,12.67,22.54,31.16,59.11,2.21,4.38,4.5,8.96,6.86,13.72,5.09,10.32,12.7,17.34,13.68,17.92.97.59,13.62,21.21,13.62,21.21l.58,8.71,12.82,18.35,11.81,24.64-4.9,11.59c3.9,9.03,7.81,18.24,11.7,27.64,1.39,3.3,2.75,6.59,4.09,9.87l10.21,8.47,7.84,25.43s2.78,14.14,5.83,28.63c5.95,16.88,11.14,32.88,15.55,47.69l6.44,10.17,11.94,42.41-1.26,15.92c.56,2.64,1.09,5.14,1.49,7.4,2.01,11.36,3.32,17.74,3.32,17.74,0,0,.97-6.34,2.37-17.99.68-5.82,1.01-12.95,1.55-21.3.25-4.17.18-8.61.25-13.35Z"
          fill="#192b51"
        />
        <path
          d="m181.39,300.29l-.3-.86-.19-.89c-2.65-12.56-5.12-25.02-5.73-28.1l-6.41-20.79-9.69-8.04-1.31-3.2c-1.33-3.24-2.68-6.5-4.05-9.77-3.68-8.9-7.48-17.88-11.64-27.51l-2.35-5.44,4.78-11.3-8.62-17.98-14.38-20.58-.61-9.15c-2.02-3.28-4.12-6.64-5.93-9.5l-85.01,17.37,6.38,12.03c-4.13,12.47-14.64,23.82-28.25,24.96-.99-1.56-1.59-2.5-1.59-2.5,0,0-2.29-3.5-4.28-1.4-2.01,2.08-3.25,2.22-.94,6.38,2.29,4.16,98.34,155.66,98.34,155.66,0,0,22.63,45.02,66.31,13.92,7.08-5.04,16.62-10.78,27.61-16.85-3.65-11.72-7.7-23.94-12.12-36.48Zm-35.45,49.7c-19.68,14.45-40.17-13.79-43.68-19.41-2.99-4.77-70.32-111.28-90.27-142.79,2.25-.45,4.41-1.12,6.5-1.96l84.45,125.5L20.72,184.84c3.7-1.8,7.09-4.19,10.09-7.04l-.08.09,49.69,70.8-45.51-75.34-.1.11c2.49-3.1,4.6-6.54,6.25-10.21l98.43,138.06s26.15,34.21,6.45,48.67Z"
          fill="#f99b1c"
        />
        <path d="m445.54,155.07v45.93h73.75v32.54h-73.75v75.85h-36.48V122.52h123.09v32.54h-86.61Z" fill="#192b51" />
        <path
          d="m649.2,309.38l-11.02-33.07h-66.4l-11.28,33.07h-38.05l67.97-186.86h28.61l68.24,186.86h-38.05Zm-43.56-131.75l-23.62,67.97h46.45l-22.83-67.97Z"
          fill="#192b51"
        />
        <path d="m705.09,309.38V122.52h36.48v154.32h84.77v32.54h-121.25Z" fill="#192b51" />
        <path
          d="m904.28,310.96c-21.52,0-37.27-7.09-50.13-19.68-18.63-18.63-18.9-39.63-18.9-75.32s.26-56.69,18.9-75.32c12.86-12.6,28.61-19.68,50.13-19.68,34.91,0,61.67,19.95,68.5,59.05h-37.27c-3.67-15.22-13.12-26.51-31.23-26.51-9.97,0-18.11,3.67-23.36,9.71-6.82,7.87-9.19,17.06-9.19,52.75s2.36,44.88,9.19,52.75c5.25,6.04,13.38,9.71,23.36,9.71,18.11,0,27.82-11.29,31.49-26.51h37.01c-6.82,39.11-33.86,59.05-68.5,59.05Z"
          fill="#192b51"
        />
        <path
          d="m1108.99,291.27c-12.6,12.6-28.34,19.68-49.86,19.68s-37.53-7.09-50.13-19.68c-18.63-18.63-18.9-39.63-18.9-75.32s.26-56.69,18.9-75.32c12.6-12.6,28.61-19.68,50.13-19.68s37.27,7.09,49.86,19.68c18.63,18.63,19.16,39.63,19.16,75.32s-.52,56.69-19.16,75.32Zm-26.51-127.81c-5.25-6.04-13.39-9.97-23.36-9.97s-18.11,3.94-23.62,9.97c-6.82,7.87-8.92,16.8-8.92,52.49s2.1,44.35,8.92,52.23c5.51,6.04,13.65,10.24,23.62,10.24s18.11-4.2,23.36-10.24c6.82-7.87,9.18-16.53,9.18-52.23s-2.36-44.62-9.18-52.49Z"
          fill="#192b51"
        />
        <path
          d="m1256.19,309.38l-74.01-114.69v114.69h-36.48V122.52h32.54l74.01,114.43v-114.43h36.48v186.86h-32.54Z"
          fill="#192b51"
        />
        <path
          d="m946.74,400.19v-59.3h39.06v10.33h-27.49v13.91h23.4v10.33h-23.4v14.41h27.49v10.33h-39.06Zm21.49-65.05h-7.25l5.33-14.16h11.41l-9.5,14.16Z"
          fill="#f99b1c"
        />
        <path
          d="m1049.68,393.95c-4.91,5.17-10.41,6.75-16.74,6.75-6.75,0-11.75-2.25-15.83-6.25-5.91-5.91-6-12.58-6-23.91s.08-17.99,6-23.9c4.08-4,9.08-6.25,15.83-6.25,13.83,0,20.74,9,22.32,18.99h-11.66c-1.33-5.67-4.75-8.66-10.66-8.66-3.16,0-5.66,1.25-7.41,3.16-2.17,2.5-2.83,5.33-2.83,16.66s.67,14.24,2.83,16.74c1.75,1.92,4.25,3.08,7.41,3.08,3.58,0,6.41-1.25,8.25-3.33,1.92-2.17,2.66-4.83,2.66-7.91v-2.16h-10.91v-9.75h22.4v8.66c0,8.58-1.33,13.58-5.66,18.07Z"
          fill="#f99b1c"
        />
        <path
          d="m1098.4,400.19l-3.5-10.49h-21.07l-3.58,10.49h-12.08l21.57-59.3h9.08l21.66,59.3h-12.08Zm-13.83-41.81l-7.5,21.57h14.74l-7.25-21.57Z"
          fill="#f99b1c"
        />
        <path
          d="m1147.62,400.19l-11.58-23.65h-8.33v23.65h-11.58v-59.3h23.24c12.08,0,19.24,8.25,19.24,18.16,0,8.33-5.08,13.49-10.83,15.58l13.24,25.57h-13.41Zm-9-48.98h-10.91v15.66h10.91c5.08,0,8.41-3.25,8.41-7.83s-3.33-7.83-8.41-7.83Z"
          fill="#f99b1c"
        />
        <path
          d="m1200.42,400.19l-11.58-23.65h-8.33v23.65h-11.58v-59.3h23.24c12.08,0,19.24,8.25,19.24,18.16,0,8.33-5.08,13.49-10.83,15.58l13.24,25.57h-13.41Zm-9-48.98h-10.91v15.66h10.91c5.08,0,8.41-3.25,8.41-7.83s-3.33-7.83-8.41-7.83Z"
          fill="#f99b1c"
        />
        <path
          d="m1255.47,400.19l-3.5-10.49h-21.07l-3.58,10.49h-12.08l21.57-59.3h9.08l21.66,59.3h-12.08Zm-13.83-41.81l-7.5,21.57h14.74l-7.25-21.57Z"
          fill="#f99b1c"
        />
        <path
          d="m1286.29,381.62h-8l-2.67-40.73h13.41l-2.75,40.73Zm-10.16,18.57v-12.33h12.41v12.33h-12.41Z"
          fill="#f99b1c"
        />
      </g>
    </svg>
  );
};

export default Logo;
